import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Card`}</h1>
    <p>{`Card is a flexible container
for grouping small chunks of related content.`}</p>
    <ComponentPreview componentName="card--default-story" hasReact hasAngular hasHTML titleAttr="Card Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Card provides a way to present
information quickly. While
flexible in nature, they will
typically have a heading and
a call to action and be used in
groups within a grid.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`The content arrangement inside of a Card is intentionally flexible.`}</li>
      <li parentName="ul">{`Card has a maximum width of `}<inlineCode parentName="li">{`425px`}</inlineCode>{`.
Using Card as full-width containers should be avoided.`}</li>
      <li parentName="ul">{`The Standout style can be applied to any Card variant.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Base`}</h3>
    <p>{`Base Card is a simple container for
related content. There are no strict
guidelines about what can go into a
Card, but a clear heading and
call to action are encouraged.`}</p>
    <ComponentPreview componentName="card--default-story" hasReact hasAngular hasHTML titleAttr="Base Card Example" mdxType="ComponentPreview" />
    <h3>{`Standout`}</h3>
    <p>{`Standout Card is used to give
visual priority to a Card.
Any Card can have this style,
but only one card should use it at a
time on the same page.`}</p>
    <ComponentPreview componentName="card--standout" hasReact hasAngular hasHTML titleAttr="Standout Card Example" mdxType="ComponentPreview" />
    <h3>{`Highlighted Header`}</h3>
    <p>{`Highlighted Header Card supplies a
small burst of information that
draws focus to its header.`}</p>
    <ComponentPreview componentName="card--highlighted-header" hasReact hasAngular hasHTML titleAttr="Highlighted Header Card Example" mdxType="ComponentPreview" />
    <h3>{`Teaser`}</h3>
    <p>{`Teaser Card includes an
image or illustration and a
call to action that can be
a `}<a parentName="p" {...{
        "href": "/using-spark/components/button"
      }}>{`Button`}</a>{` or a `}<a parentName="p" {...{
        "href": "/using-spark/components/link"
      }}>{`Link`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`When using an Icon in Teaser Card, the
content of the Card should be center aligned.`}</li>
      <li parentName="ul">{`The Teaser Card element order can
be changed, for example, if you’d
like the heading to appear above the image.`}</li>
    </ul>
    <ComponentPreview componentName="card--teaser" hasReact hasAngular hasHTML titleAttr="Teaser Card Example" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`The contents of Card are intentionally flexible.`}</li>
      <li parentName="ul">{`Card may include a clear heading and call to action.`}</li>
      <li parentName="ul">{`Teaser Card must have a heading, image, and call to action.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      